import React from 'react'

type Props = {
  message: React.ReactNode
}

function Banner({ message }: Props) {
  return (
    <div className="fixed top-0 left-0 z-[11] flex w-[100%] flex-row items-center justify-center bg-linearGradientApprove px-[4px] py-[1px] text-center">
      {message}
    </div>
  )
}

export default Banner
