import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { assetListMap } from '../../constants/contract/asset'
import { RouteType, navList } from '../../constants/routes'
import { useWeb3 } from '../../context/Web3Context'
import viewOnIcon from '../../public/assets/icons/blank-page.svg'
import {
  useBoostedPoolRewarderData,
  useBribeConfigData,
  useVotingData,
} from '../../store/MulticallData/hooks'
import { getAssetsInfoByDeployerOrOperator } from '../../utils/voting'
import Dropdown from '../Dropdown'
import IconChevDown from '../Icons/IconChevDown'
import MenuNav from '../MenuNav'

const RouterTabs = ({ isOpenMenu = false, onBack = () => {} }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { chainId } = useWeb3()
  const [showBribeLink, setShowBribeLink] = useState(false)
  const votingData = useVotingData()
  const bribeConfigData = useBribeConfigData()
  const poolRewarderData = useBoostedPoolRewarderData()

  useEffect(() => {
    const assetList = assetListMap[chainId]
    const isbribeDeployerOfEachAsset = votingData.withAccount?.isbribeDeployerOfEachAsset
    const isUserHasBribeRoleOfEachAsset = bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset
    let hasRoles =
      getAssetsInfoByDeployerOrOperator(
        isbribeDeployerOfEachAsset,
        isUserHasBribeRoleOfEachAsset,
        assetList,
        'bribeRewarder'
      ).length > 0

    if (!hasRoles) {
      hasRoles =
        getAssetsInfoByDeployerOrOperator(
          poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
          poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
          assetList,
          'boostedPoolRewarder'
        ).length > 0
    }
    setShowBribeLink(hasRoles)
  }, [
    bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset,
    chainId,
    poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
    poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
    votingData.withAccount?.isbribeDeployerOfEachAsset,
  ])

  const [desktopRoutes, setDesktopRoutes] = useState<RouteType[]>([])

  useEffect(() => {
    console.log(showBribeLink, 'Bribe')
    setDesktopRoutes(
      navList({
        isHiddenBribe: !showBribeLink,
        chainId: chainId,
      })
    )
  }, [showBribeLink, chainId])

  return (
    <>
      {isOpenMenu && <MenuNav routers={desktopRoutes} onBack={onBack}></MenuNav>}
      <div className="ml-3 hidden md:block lg:ml-8">
        <div className="flex gap-4 p-1 font-Work text-xs font-semibold text-wombatBrown1 lg:text-base">
          {desktopRoutes.map((route) => {
            if (route.children) {
              return (
                <Dropdown
                  key={route.name}
                  onClick={(e) => {
                    if (e?.isExternalLink !== true) {
                      navigate(e?.path || '')
                    } else {
                      open(e?.path, '_blank')
                    }
                  }}
                  options={route.children}
                >
                  <div className="flex items-center gap-1">
                    {route.name}
                    <IconChevDown strokeColor="#695b51"></IconChevDown>
                  </div>
                </Dropdown>
              )
            }
            return (
              <button
                key={route.name}
                id={`routertabs-${route.name.toLowerCase()}`}
                className={`${
                  route.path === pathname && 'font-extrabold text-wombatBrown'
                } button-hover-opacity uppercase`}
                onClick={() => {
                  if (route.isExternalLink !== true) {
                    navigate(route.path)
                  }
                }}
              >
                {route.isExternalLink === true ? (
                  <a href={route.path} target="_blank" rel="noreferrer">
                    <span>{route.name}</span>
                    &nbsp;
                    <Image
                      src={viewOnIcon}
                      height={14}
                      width={14}
                      style={{ marginLeft: '2px', marginTop: '1px' }}
                    />
                  </a>
                ) : (
                  <span>{route.name}</span>
                )}
              </button>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default RouterTabs
