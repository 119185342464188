import Image from 'next/image'
import warningIcon from '../../public/assets/icons/warning-icon.svg'

type Props = {
  switchChainButtonClicked: () => void
}

export default function WrongNetworkIndicator({ switchChainButtonClicked }: Props) {
  return (
    <div className="flex">
      <button
        className="flex max-w-xs items-center border-2 border-red-400 bg-white py-[4px] px-4 font-Roboto font-semibold text-[#FF4E52] md:py-[6px] md:px-3"
        onClick={() => switchChainButtonClicked()}
      >
        <Image layout="fixed" src={warningIcon} width={30} />
        <span className="hidden whitespace-nowrap md:flex">Wrong Network</span>
      </button>
    </div>
  )
}
