import { CSSProperties } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import viewOnIcon from '../../public/assets/icons/blank-page.svg'

import Image from 'next/image'
import { RouteType } from '../../constants/routes'
const MenuNav = ({
  style,
  className = '',
  routers = [],
  onBack = () => {},
}: {
  style?: CSSProperties
  className?: string
  routers?: RouteType[]
  onBack?: () => void
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  return (
    <div
      style={style}
      onTouchMove={(e) => e.preventDefault()}
      className={`
        fixed
        left-0
        top-0
        z-[999]
        h-[100vh]
        w-[100vw]
        bg-white
        bg-linearMenu
        px-6
        py-10
        md:hidden
        ${className}
      `}
    >
      <button onClick={onBack} className="flex items-center gap-1 text-sm font-semibold">
        <Image src="/assets/icons/arrow-left-thin.svg" alt="back" width={12} height={12}></Image>
        BACK
      </button>
      <div className="mt-8 flex flex-col gap-8">
        {routers.map((route) => {
          if (route.children) {
            return (
              <div key={route.name}>
                <div className="text-base font-bold text-wombatBrown1">{route.name}</div>
                <ul className="text-xs font-semibold text-wombatBrown1/60">
                  {route.children.map((item, index) => (
                    <li
                      className={`mt-3 ${
                        route.path === pathname && 'font-extrabold text-wombatBrown'
                      }`}
                      key={index}
                      onClick={() => {
                        if (item.isExternalLink !== true) {
                          navigate(item.path)
                        } else {
                          open(item.path, '_blank')
                        }
                        onBack()
                      }}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )
          }
          return (
            <button
              key={route.name}
              id={`routertabs-${route.name.toLowerCase()}`}
              className={`
                text-base font-bold text-wombatBrown1
                ${route.path === pathname && 'font-extrabold text-wombatBrown'} 
                button-hover-opacity w-fit uppercase`}
              onClick={() => {
                if (route.isExternalLink !== true) {
                  navigate(route.path)
                }
              }}
            >
              {route.isExternalLink === true ? (
                <a href={route.path} target="_blank" rel="noreferrer">
                  <span>{route.name}</span>
                  &nbsp;
                  <Image
                    src={viewOnIcon}
                    height={14}
                    width={14}
                    style={{ marginLeft: '2px', marginTop: '1px' }}
                  />
                </a>
              ) : (
                <span>{route.name}</span>
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default MenuNav
