import Image from 'next/image'
import { useCallback } from 'react'
import { TokenSymbols } from '../../configx/tokenSymbols'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { SUPPORTED_WALLETS, WalletId } from '../../constants/web3/wallets'
import { useTxnReceipt } from '../../context/TxnReceiptProvider'
import { useWeb3 } from '../../context/Web3Context'
import usePollingWpPoints from '../../hooks/wpp/usePollingWpPoints'
import viewOnIcon from '../../public/assets/icons/blank-page-white.svg'
import ViewOnIcon from '../../public/assets/icons/blank-page.svg'
import WOMIcon from '../../public/assets/tokens/WOM.svg'
import { useTokenData } from '../../store/MulticallData/hooks'
import { useWpPointsForUser, useWppNeedAuth } from '../../store/WpPoints/hooks'
import { getExplorerLink } from '../../utils/getBscScanLink'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import Modal from '../Modal'
import SmartAddress from '../SmartAddress'

const timeSince = (timeStamp: number) => {
  const date = new Date(timeStamp * 1000)
  const now = new Date()
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)
  const hours = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds - hours * 3600) / 60)
  return hours >= 1 ? `${hours}h${minute}'ago` : `${minute}'ago`
}

export default function YourWalletModal({
  isOpen,
  onClose,
  isHideMobileNav,
}: {
  isOpen: boolean
  onClose: () => void
  isHideMobileNav: boolean
}) {
  const { clearTxnReceipt, isLoaddingReceiptTxn, receiptTxn } = useTxnReceipt()
  const { account, connect, disconnect, activeWalletId, chainId, chainInfo } = useWeb3()
  const { withAccount } = useTokenData()
  const nativeTokenBalance = withAccount?.balances[NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]]
  const womBalance = withAccount?.balances[TokenSymbols.WOM]
  const currentProviderIcon = useCallback(() => {
    if (activeWalletId) {
      const wallet = SUPPORTED_WALLETS[activeWalletId]
      return <Image alt={''} src={wallet.icon} height={37} width={37} />
    }
    return
  }, [activeWalletId])

  const wpPoints = useWpPointsForUser(account || '')
  const wppNeedAuth = useWppNeedAuth()

  const changeProviderList = useCallback(() => {
    if (activeWalletId && activeWalletId !== WalletId.GNOSISSAFE) {
      return (
        <div className="flex flex-col space-y-2 md:w-full">
          {Object.values(SUPPORTED_WALLETS)
            .filter((wallet) => {
              const isWalletDifferentAsActiveAndNotHidden =
                activeWalletId !== wallet.id && !wallet.isHidden
              // injected wallet's other provider requires non injected wallet
              return SUPPORTED_WALLETS[activeWalletId].isWindowEthereum
                ? isWalletDifferentAsActiveAndNotHidden && !wallet.isWindowEthereum
                : isWalletDifferentAsActiveAndNotHidden
            })
            .map((wallet) => (
              <button
                key={`changeProviderList_${wallet.name}`}
                className="flex h-12 items-center justify-center rounded-lg border-2 border-wombatBrown2 px-[72px] py-3 font-Jamjuree shadow-lg  md:w-full"
                onClick={() => {
                  disconnect()
                  onClose()
                  connect(wallet)
                }}
              >
                <Image
                  alt={''}
                  src={wallet.groupIcon.src}
                  width={wallet.groupIcon.width || 144}
                  height={wallet.groupIcon.height || 25}
                />
              </button>
            ))}
        </div>
      )
    }
  }, [activeWalletId, connect, disconnect, onClose])

  usePollingWpPoints()

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen={isOpen} onClose={onClose} isHideMobileNav={isHideMobileNav}>
      <div className="flex max-h-[600px] flex-col justify-between md:relative md:max-h-full">
        <div>
          <div className="flex items-center justify-between">
            <p className="font-Work text-base font-semibold">Your Wallet</p>
            {activeWalletId !== WalletId.GNOSISSAFE && (
              <button
                className="rounded border-1 border-wombatRed px-[4px] py-[6px] font-Work text-xs text-wombatRed"
                onClick={async () => {
                  onClose()
                  disconnect()
                }}
              >
                Disconnect
              </button>
            )}
          </div>
          <div className="flex flex-row-reverse justify-center">
            <div>
              <div className="mt-5 flex items-center font-Work">
                <div className="flex items-center">{currentProviderIcon()}</div>
                <div className="ml-2 flex items-center">
                  <SmartAddress />
                </div>
              </div>
              <div className="mt-5 font-medium text-wombatBrown1">
                <div className="m-auto mt-2 flex w-full justify-between">
                  <div className="flex">
                    <div className="flex items-center">
                      <Image alt={''} src={chainInfo.icon} height={14} width={14} />
                    </div>
                    <div className="ml-2 font-Helvetica text-sm">
                      {chainInfo.nativeCurrency.symbol}
                    </div>
                  </div>
                  <div className="flex items-center font-Helvetica text-xs">
                    {formatNumberUSLocale(nativeTokenBalance ?? '0')}
                  </div>
                </div>
                <div className="m-auto mt-2 flex w-full justify-between">
                  <div className="flex">
                    <div className="flex items-center">
                      <Image alt={''} src={WOMIcon} height={14} width={14} />
                    </div>
                    <div className="ml-2 font-Helvetica text-sm">WOM</div>
                  </div>
                  <div className="flex items-center font-Helvetica text-xs">
                    {formatNumberUSLocale(womBalance ?? '0')}
                  </div>
                </div>
                <div className={'mt-2 w-full bg-[#5178bb] px-2 py-2 '}>
                  <a
                    href={'https://www.wompets.com/'}
                    target={'_blank'}
                    className="m-auto flex w-full justify-between text-white"
                  >
                    <div className="flex items-center gap-1 font-Helvetica text-sm">
                      WP Points
                      <Image src={viewOnIcon} height={14} width={14} />
                    </div>
                    <div className="flex items-center font-Helvetica text-xs">
                      {formatNumberUSLocale(`${wpPoints}` ?? '0')}
                    </div>
                  </a>
                  {wppNeedAuth && !wpPoints && (
                    <div className={'text-sm text-white'}>
                      Please login to activate WPP at{' '}
                      <a
                        href={'https://www.wompets.com/'}
                        className={'underline'}
                        target={'_blank'}
                      >
                        Wompets.com
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-7 flex justify-between">
            <div className="flex items-center font-Work text-base font-thin">
              Recent Transactions
            </div>
            <div>
              <button
                className="rounded border-1 border-wombatRed px-[4px] py-[6px] font-Work text-xs text-wombatRed"
                onClick={() => {
                  clearTxnReceipt()
                }}
              >
                Clear all
              </button>
            </div>
          </div>
          {!receiptTxn?.length && (
            <div className="font-Work text-xs font-normal">Start your first transaction.</div>
          )}
          {/* Receipt transaction section */}
          {isLoaddingReceiptTxn ? (
            <div className="mt-4 text-center font-Work">Loading\u2026</div>
          ) : (
            <table className="mt-4 w-full table-fixed font-Work text-xs">
              <thead>
                <tr>
                  <th className="w-6/12">Txn hash</th>
                  <th className="w-3/12 text-right md:text-center">Block</th>
                  <th className="w-3/12 text-right">Time</th>
                </tr>
              </thead>
              <tbody>
                {typeof receiptTxn !== 'string' &&
                  receiptTxn?.map((item) => (
                    <tr key={item.blockNumber}>
                      <td>
                        {/* For desktop */}
                        <a
                          href={getExplorerLink(item.hash, 'transaction', chainId)}
                          className="hidden visited:text-purple-600 md:block"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.hash.slice(0, 28)}...
                        </a>
                        <a
                          href={getExplorerLink(item.hash, 'transaction', chainId)}
                          className="block visited:text-purple-600 md:hidden"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.hash.slice(0, 20)}...
                        </a>
                      </td>
                      <td className="text-right md:text-center">
                        <a
                          href={getExplorerLink(item.blockNumber, 'block', chainId)}
                          className="visited:text-purple-600"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.blockNumber}
                        </a>
                      </td>
                      <td className="text-right">{timeSince(parseInt(item.timeStamp))}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="mt-16">
          <button
            onClick={() => account && window.open(getExplorerLink(account, 'address', chainId))}
            className="flex w-full items-center justify-center"
          >
            <div className="font-Helvetica text-xs font-normal underline">
              <a>View more on {chainInfo.blockExplorers?.default.name}</a>
            </div>
            <div className="ml-1 flex items-center">
              <Image alt={''} src={ViewOnIcon} height={24} width={24} />
            </div>
          </button>
          <div className="mt-1 flex flex-col items-center justify-center space-y-2">
            {changeProviderList()}
          </div>
        </div>
      </div>
    </Modal>
  )
}
