import Image from 'next/image'
import { ChainInfo } from '../../constants/web3/chains'

type Props = {
  chainInfo: ChainInfo
  networkBoxButtonClicked: () => void
}

export function ChainInfoButton({ chainInfo, networkBoxButtonClicked }: Props) {
  return (
    <button
      onClick={() => networkBoxButtonClicked()}
      className="button-hover-opacity-light mr-1 flex items-center space-x-1 whitespace-nowrap bg-white p-0.5 px-2 md:mt-0 md:space-x-1 md:p-1 md:px-3"
    >
      <div className="h-5 w-5">
        <div>
          <Image alt={''} src={chainInfo.icon} width={20} height={20}></Image>
        </div>
      </div>
      <p className="m-1 min-w-[4rem] font-Roboto font-semibold text-wombatBrown1">
        {chainInfo ? chainInfo.label : ''}
      </p>
    </button>
  )
}
