import Button from '../Button'

type Props = {
  walletAddress: string
  walletAddressButtonClicked: () => void
  sidName: string | null
}

export default function WalletAddressButton({
  walletAddress,
  walletAddressButtonClicked,
  sidName,
}: Props) {
  return (
    <Button
      id="wallet-address-button"
      className="button-hover-opacity-light py-1.5 md:py-0"
      onClick={() => walletAddressButtonClicked()}
    >
      <p className="hidden whitespace-nowrap font-Jamjuree text-base md:flex">
        {sidName
          ? sidName
          : `${walletAddress.substring(0, 10)}...${walletAddress.substring(
              walletAddress.length - 4
            )}`}
      </p>
      {/* show shorter address on mobile */}
      <p className="flex whitespace-nowrap font-Jamjuree text-base md:hidden">
        {sidName
          ? sidName
          : `${walletAddress.substring(0, 6)}...${walletAddress.substring(
              walletAddress.length - 4
            )}`}
      </p>
    </Button>
  )
}
