/* eslint-disable @typescript-eslint/ban-ts-comment */
/* This example requires Tailwind CSS v2.0+ */
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  ROUTES_TO_BE_HIDDEN,
  RouteId,
  devRoutes,
  routes as originalRoutes,
} from '../../constants/routes'
import { chainInfos } from '../../constants/web3/chains'
import { useWalletSelected } from '../../context/WalletSelectedContext'
import { useWeb3 } from '../../context/Web3Context'
import { useSpaceId } from '../../context/spaceIdContext'
import analytics_icon from '../../public/assets/icons/analytics.svg'
import button_close from '../../public/assets/icons/button-close.svg'
import risk from '../../public/assets/icons/risk.svg'
import logo from '../../public/assets/wombatLogo/wombat_logo_trans_2.svg'
import logoNoText from '../../public/assets/wombatLogo/wombat_logo_trans_no_text.svg'
import Banner from '../Banner'
import Button from '../Button'
import { ChainInfoButton } from '../ChainInfoButton'
import { ConnectWalletButton } from '../ConnetWalletButton'
import CustomRpcModal from '../CustomRpcModal'
import Drawer from '../Drawer'
import IconMenu from '../Icons/IconMenu'
import RouterTabs from '../RouterTabs'
import SelectWalletModal from '../SelectWalletModal'
import SwitchNetworkModal from '../SwitchNetwork'
import { TncModal } from '../TncModal'
import WalletAddressButton from '../WalletAddressButton'
import WrongNetworkIndicator from '../WrongNetworkIndicator'
import YourWalletModal from '../YourWalletModal'
import { ShowMoreSvg } from '../images'
const externalNavigation: {
  name: string
  href: string
  isInternalLink?: boolean
}[] = [
  {
    name: 'Analytics',
    href: 'https://info.wombat.exchange',
  },
  {
    name: 'Airdrop',
    href: 'https://airdrop.wombat.exchange',
  },
  {
    name: 'Withdrawal page',
    href: originalRoutes.TOKEN_WITHDRAWAL.path,
    isInternalLink: true,
  },
  {
    name: 'Documentations',
    href: 'https://docs.wombat.exchange',
  },
  {
    name: 'Governance Forum',
    href: 'https://gov.wombat.exchange',
  },
  {
    name: 'Social',
    href: 'https://www.wombat.exchange/#community',
  },
  {
    name: 'Risk',
    href: 'https://docs.wombat.exchange/getting-started/risks',
  },
  {
    name: 'Private RPC',
    href: '',
  },
]

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default function Navbar({ children }: Props) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { open: openModalSelectWallet, isOpen: isWalletSelectedOpen } = useWalletSelected()
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [isOpenSwitchNetworkModal, setIsOpenSwitchNetworkModal] = useState<boolean>(false)
  const { isSupported: isOnSupportedChain, chainId, account, switchNetwork } = useWeb3()
  const router = useRouter()
  const sidName = useSpaceId()
  const [isOpenYourWalletModal, setIsOpenYourWalletModal] = useState<boolean>(false)
  const inactiveColor = '#695B51'
  const activeColor = '#FFCD1A'
  const WRONG_NETWORK = !isOnSupportedChain
  const CONNECT_WALLET = !account
  const chainInfo = chainId ? chainInfos[chainId] : null
  document.body.classList.toggle('disable-scrolling', openDrawer)
  const [isOpenTncModal, setIsOpenTncModal] = useState<boolean>(false)
  const [customRpcModal, setCustomRpcModal] = useState<boolean>(false)
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  // const headerRef = useRef<HTMLDivElement>(null);
  // const [headerHeight, setHeaderHeight] = useState(0);

  // useEffect(() => {
  //   const updateHeaderHeight = () => {
  //     if (headerRef.current) {
  //       setHeaderHeight(headerRef.current.offsetHeight);
  //     }
  //   };
  //   updateHeaderHeight();
  //   window.addEventListener('resize', updateHeaderHeight);

  //   return () => {
  //     window.removeEventListener('resize', updateHeaderHeight);
  //   };
  // }, []);
  const iconMenuColors = useMemo(() => {
    return {
      bg: isOpenMenu ? '#B672D6' : '#F9F6EF',
      color: isOpenMenu ? '#FFF' : '#352B1B',
    }
  }, [isOpenMenu])

  //hide mobile nav bar when below modal has opened:
  const isHideMobileNav = isWalletSelectedOpen || isOpenSwitchNetworkModal || isOpenYourWalletModal
  const localIsDev = localStorage.getItem('isDev')
  const isDev = localIsDev ?? localIsDev === 'true'
  const routes = isDev ? devRoutes : originalRoutes

  useEffect(() => {
    const currentUnixTime = Math.floor(Date.now() / 1000)
    const tncExpirationUnixTime = localStorage.getItem('tnc-expiration-unix-time')
    // 1. check whether user has accept T&C before
    if (!tncExpirationUnixTime) {
      setIsOpenTncModal(true)
      return
    }

    // 2. check whether acceptance date has been outdated
    const hasTncExpirationOutdated = currentUnixTime > Number(tncExpirationUnixTime)
    if (!hasTncExpirationOutdated) return
    setIsOpenTncModal(true)
  }, [pathname])

  const LinkText = ({ name }: { name: string }) => {
    return (
      <div
        className="mb-3 flex w-full items-center justify-center px-3 py-2 font-bold uppercase	hover:rounded hover:bg-wombatDrawerLinkHoverBackground md:mb-6"
        onClick={() => {
          setOpenDrawer(false)
        }}
      >
        <div className="h-4 w-4">
          {name === 'Risk' && <Image alt={''} src={risk} width={16} height={16} />}
        </div>
        <div className="font-Jamjuree text-lg font-bold">{name}</div>
      </div>
    )
  }

  return (
    <div>
      <Head>
        <title>Wombat Exchange</title>
        <meta
          name="description"
          content=" Hyper efficient multi-chain stableswap. #BSC and beyond."
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Banner
        message={
          <div className="flex flex-row items-center justify-center font-Jamjuree text-base max-md:text-sm">
            <div className="relative bottom-[-5px] mr-[6px]">
              <Image
                alt={''}
                src="/assets/wombatLogo/wombat_banner.svg"
                width={40}
                height={40}
                layout="fixed"
              />
            </div>
            <span>
              <span className="font-bold">PHISHING WARNING:</span> Make sure you’re visiting{' '}
              <span className="text-wombatYellow3">https://app.wombat.exchange/</span> - Bookmark
              the URL.
            </span>
          </div>
        }
      />
      <header className="font-Work" id="navbar">
        {/* pt includes the height of the banner */}
        <div className="flex w-full items-center justify-between px-[26px] pb-[24px] pt-[73px] md:px-8">
          <div className="flex items-center">
            <div id="logo" className="hidden items-center md:flex">
              <a className="flex items-center" href="https://www.wombat.exchange/">
                <Image alt={''} layout="fixed" width={120} height={50} src={logo} />
              </a>
            </div>
            <div className="flex items-center md:hidden">
              <a className="flex items-center" href="https://www.wombat.exchange/">
                <Image alt={''} layout="intrinsic" width={30} height={30} src={logoNoText} />
              </a>
            </div>
            <RouterTabs isOpenMenu={isOpenMenu} onBack={() => setIsOpenMenu(false)} />
          </div>
          <div>
            <div id="navbar-buttons" className="flex items-center">
              {chainInfo != null ? (
                <ChainInfoButton
                  chainInfo={chainInfo}
                  networkBoxButtonClicked={() => setIsOpenSwitchNetworkModal(true)}
                />
              ) : (
                <></>
              )}
              <div className="flex">
                {WRONG_NETWORK ? (
                  <WrongNetworkIndicator switchChainButtonClicked={() => switchNetwork(chainId)} />
                ) : CONNECT_WALLET ? (
                  <ConnectWalletButton connectWalletButtonClicked={() => openModalSelectWallet()} />
                ) : (
                  <WalletAddressButton
                    walletAddress={account}
                    sidName={sidName}
                    walletAddressButtonClicked={() => setIsOpenYourWalletModal(true)}
                  />
                )}
                <a
                  className="flex"
                  target="_blank"
                  href={`https://info.wombat.exchange/#/${chainInfo?.network}/overview`}
                  rel="noreferrer"
                >
                  <Button className="button-hover-opacity-light ml-1.5 hidden px-2 md:block">
                    <Image alt={''} layout="fixed" src={analytics_icon} />
                  </Button>
                </a>
              </div>
              <button
                id="navbar-menu-desktop"
                className="button-hover-opacity ml-0 hidden items-center p-2 md:block"
                onClick={() => setOpenDrawer(true)}
              >
                <ShowMoreSvg fill="#5C5C5C" />
              </button>
              <div className="block md:hidden" onClick={() => setIsOpenMenu(true)}>
                <IconMenu bgColor={iconMenuColors.bg} color={iconMenuColors.color}></IconMenu>
              </div>
            </div>
            <div className="hidden md:flex">
              <Drawer isOpen={openDrawer} setIsOpen={setOpenDrawer}>
                <button
                  className="button-hover-opacity-light ml-auto flex justify-end p-6 pr-5"
                  onClick={() => setOpenDrawer(false)}
                >
                  <Image alt={''} src={button_close} width={20} height={20} />
                </button>
                <div className="w-11/12 pt-8">
                  {externalNavigation.map((nav) => {
                    return (
                      <React.Fragment key={nav.name}>
                        {!nav.isInternalLink ? (
                          nav.name === 'Private RPC' ? (
                            <div
                              className={'cursor-pointer'}
                              onClick={() => {
                                setCustomRpcModal(true)
                              }}
                            >
                              <LinkText name={nav.name} />
                            </div>
                          ) : (
                            <a
                              id={`navbar-${nav.name.toLowerCase()}-desktop`}
                              target="_blank"
                              href={
                                nav.name.toLowerCase() === 'analytics'
                                  ? `${nav.href}/#/${chainInfo?.network}/overview`
                                  : nav.href
                              }
                              rel="noreferrer"
                            >
                              <LinkText name={nav.name} />
                            </a>
                          )
                        ) : (
                          <Link to={nav.href}>
                            <LinkText name={nav.name} />
                          </Link>
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </header>

      <main className="relative w-full overflow-x-hidden font-VT323" id="main">
        {/* Main content */}
        <div>{children}</div>
        {/* Modals */}
        <TncModal
          isOpen={isOpenTncModal && !pathname.includes(routes[RouteId.TNC].path)}
          onClose={() => setIsOpenTncModal(false)}
          isHideMobileNav
        />
        <YourWalletModal
          isOpen={isOpenYourWalletModal}
          onClose={() => setIsOpenYourWalletModal(false)}
          isHideMobileNav
        />
        <SwitchNetworkModal
          isOpen={isOpenSwitchNetworkModal}
          onClose={() => setIsOpenSwitchNetworkModal(false)}
          isHideMobileNav
        />
        <SelectWalletModal isHideMobileNav />
        <CustomRpcModal
          isOpen={customRpcModal}
          onClose={() => setCustomRpcModal(false)}
          isHideMobileNav
        ></CustomRpcModal>
        {openDrawer && (
          <div
            className={`fixed inset-0 z-20 flex h-screen items-center justify-center bg-wombatDrawerMobileBackground transition-all delay-1000 duration-500 md:hidden`}
          >
            <button
              className="button-hover-opacity-light absolute right-6 top-6 m-4 justify-end"
              onClick={() => setOpenDrawer(false)}
            >
              <Image alt={''} src={button_close} width={20} height={20}></Image>
            </button>
            <div className="-mt-16 h-[100%] w-11/12 overflow-auto pt-[5rem]">
              {/** Mobile Hamburgar Menu */}
              {/** Internal Navigation */}
              {Object.entries(routes)
                .filter(
                  ([routeId, route]) =>
                    route.hideOnMobileNavbar &&
                    !ROUTES_TO_BE_HIDDEN[routeId as RouteId]?.includes(chainId)
                )
                .map(([, route]) => {
                  return (
                    <div
                      key={route.name}
                      className="mb-3 flex w-full cursor-pointer items-center justify-center px-3 py-2 font-Work font-bold	uppercase hover:bg-wombatDrawerLinkHoverBackground md:mb-6"
                      onClick={() => {
                        setOpenDrawer(false)
                        navigate(route.path)
                      }}
                    >
                      <div className="font-Jamjuree text-lg">{route.name}</div>
                    </div>
                  )
                })}
              {/** External Navigation */}
              {externalNavigation.map((nav) =>
                nav.name === 'Private RPC' ? (
                  <div key={nav.name} id={`navbar-${nav.name.toLowerCase()}-mobile`}>
                    <div
                      className="mb-3 flex w-full  items-center justify-center px-3 py-2 font-Work font-bold uppercase	hover:bg-wombatDrawerLinkHoverBackground md:mb-6"
                      onClick={() => {
                        setOpenDrawer(false)
                        setCustomRpcModal(true)
                      }}
                    >
                      <div className="font-Jamjuree text-lg">{nav.name}</div>
                    </div>
                  </div>
                ) : (
                  <a
                    key={nav.name}
                    id={`navbar-${nav.name.toLowerCase()}-mobile`}
                    href={nav.href}
                    rel="noreferrer"
                  >
                    <div
                      className="mb-3 flex w-full  items-center justify-center px-3 py-2 font-Work font-bold uppercase	hover:bg-wombatDrawerLinkHoverBackground md:mb-6"
                      onClick={() => {
                        setOpenDrawer(false)
                        router.push(nav.href)
                      }}
                    >
                      <div>
                        {nav.name === 'Risk' && <Image src={risk} width={15} height={15} />}
                      </div>
                      <div className="font-Jamjuree text-lg">{nav.name}</div>
                    </div>
                  </a>
                )
              )}
            </div>
          </div>
        )}
      </main>
      {/* Navbav on mobile */}
      {!isHideMobileNav && (
        <div
          id="mobile-navbar"
          className={'fixed bottom-0 z-10 grid h-16 w-full grid-cols-5 bg-white md:hidden'}
        >
          {Object.entries(routes)
            .filter(
              ([routeId, route]) =>
                route.visibleOnNavbar &&
                !route.hideOnMobileNavbar &&
                !ROUTES_TO_BE_HIDDEN[routeId as RouteId]?.includes(chainId)
            )
            .map(([, route]) => {
              const filledColor = pathname.includes(route.path) ? activeColor : inactiveColor
              return (
                <button
                  key={route.name}
                  id={`navbar-${route.name.toLowerCase()}`}
                  className="button-hover-opacity flex items-center justify-center"
                  onClick={() => {
                    navigate(route.path)
                  }}
                >
                  <div>
                    <div className="mb-1 flex justify-center">
                      <route.footerSvg fill={filledColor} />
                    </div>
                    <p
                      className={`text-center text-[14px] ${
                        pathname.includes(route.path) ? 'text-wombatYellow' : 'text-wombatBrown1'
                      }
                font-Jamjuree font-bold uppercase`}
                    >
                      {route.name}
                    </p>
                  </div>
                </button>
              )
            })}
          <button
            className="button-hover-opacity flex items-center justify-center"
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <p id="navbar-menu-mobile" className="h-5 text-xl leading-3 tracking-wider ">
              <ShowMoreSvg fill={inactiveColor} />
            </p>
          </button>
        </div>
      )}
    </div>
  )
}
