import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWeb3 } from '../../context/Web3Context'
import { chainInfos } from '../../constants/web3/chains'
interface IRouteComponentWrapper {
  children: React.ReactNode
}
export const RouteComponentWrapper = ({ children }: IRouteComponentWrapper) => {
  const [, setSearchParams] = useSearchParams()
  const { chainId } = useWeb3()
  const isFirstTimeRender = useRef(true)

  /**
   * Update "chain" query string when users switch chains by clicking network button on our Dapp.
   */
  useEffect(() => {
    // prevent from overwriting the query string when users enter a url on the broswer
    if (isFirstTimeRender.current)
      return () => {
        isFirstTimeRender.current = false
      }
    setSearchParams((prev) => {
      const chainInfo = chainInfos[chainId]
      prev.set('chain', chainInfo.network)
      return prev
    })
  }, [chainId, setSearchParams])

  return <>{children}</>
}
