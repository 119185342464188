import { useWeb3 } from '../../context/Web3Context'
import { useAppDispatch } from '../../store/hooks'
import useSWR from 'swr'
import getWpPoints from '../../store/WpPoints/thunks/getWpPoints'

const usePollingWpPoints = () => {
  const { account } = useWeb3()
  const dispatch = useAppDispatch()
  useSWR(
    [account, 'getWpPoints'],
    ([account]) => {
      if (account) {
        dispatch(
          getWpPoints({
            user: account || '',
          })
        )
      }
    },
    {
      refreshInterval: 10000,
    }
  )
}

export default usePollingWpPoints
