import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import { routes } from '../../constants/routes'
import { SECONDS_PER_DAY, TNC_CACHED_DAY } from '../../constants/common'
import WButton, { Variant } from '../WButton'
import { Link } from 'react-router-dom'

interface Props {
  isOpen: boolean
  onClose: () => void
  isHideMobileNav: boolean
}

export const TncModal = ({ isOpen, onClose, isHideMobileNav }: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const currentUnixTime = Math.floor(Date.now() / 1000)

  const handleAcceptBtnOnClick = () => {
    const numDayTillExpiry = isChecked ? TNC_CACHED_DAY : 1

    localStorage.setItem(
      'tnc-expiration-unix-time',
      (currentUnixTime + SECONDS_PER_DAY * numDayTillExpiry).toString()
    )

    document.body.style.overflow = 'initial'
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={550}
      height={440}
      title={<div className="mt-2 text-lg">Terms and Conditions</div>}
      variant="bordered"
      isHideMobileNav={isHideMobileNav}
      disableOutsideClick
      hideButtonClose
      isShowBackButton={false}
      disableScrolling
    >
      <div className="font-Work">
        <Link
          to="/"
          className="font-bold text-wombatPurple"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wombat Exchange{' '}
        </Link>
        and its related websites are not available to, are not intended for, and any communications
        from Wombat Exchange or its representatives are not intended to be acted upon by, any
        persons or entities who are located or incorporated in (i) the USA, (ii) the United Kingdom,
        (iii) Hong Kong, (iv) any country which are subject to any sanctions, including those
        programs run by OFAC and the United Nations Security Council, or (v) any person in a
        restricted country.
        <br />
        <br />
        By proceeding to the site, you confirm you have read and agree to the above, the{' '}
        <Link
          to={routes.TNC.path}
          className="font-bold text-wombatPurple"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions.
        </Link>
        <br />
        <br />
        <div className="flex">
          <input
            type="checkbox"
            className="mr-2 w-[18px]"
            onChange={() => setIsChecked((prev) => !prev)}
            checked={isChecked}
          />
          <div onClick={() => setIsChecked((prev) => !prev)}>
            {' '}
            Click here to hide this message for 30 days.
          </div>
        </div>
        <br />
        <WButton
          variant={Variant.PURPLE}
          width="w-full"
          height="h-10"
          onClick={handleAcceptBtnOnClick}
        >
          Accept
        </WButton>
      </div>
    </Modal>
  )
}
