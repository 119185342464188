import Image from 'next/image'
import { useSpaceId } from '../../context/spaceIdContext'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { useWeb3 } from '../../context/Web3Context'
import CopyIcon from '../../public/assets/icons/copy-icon.svg'
import { shortenAddress } from '../../utils'

export default function SmartAddress() {
  const { account: address } = useWeb3()
  const sidName = useSpaceId()
  const addressShort = address ? shortenAddress(address, 4) : ''
  const copyToClipboard = useCopyToClipboard(sidName || address)
  if (!address) return null

  return (
    <>
      <div className="flex max-w-sm items-center">
        <p className="font-Jamjuree text-lg font-bold">
          &nbsp;
          {sidName ? sidName : addressShort}
        </p>
        <button className="ml-2 flex items-center" onClick={copyToClipboard}>
          <Image src={CopyIcon} height={20} width={20} />
        </button>
      </div>
    </>
  )
}
