import Link from 'next/link'
import { RouteType } from '../../constants/routes'
const Dropdown = ({
  children,
  isLink = false,
  options = [],
  onClick = () => {},
}: {
  children?: React.ReactNode
  options: RouteType[]
  isLink?: boolean
  onClick?: (e?: RouteType) => void
}) => {
  return (
    <div className=" group relative cursor-pointer">
      {children}
      <ul className=" absolute left-1/2 top-full z-50 hidden w-fit -translate-x-1/2 whitespace-nowrap border border-[#F1EAF3] bg-white p-2 text-xs group-hover:block">
        {options.map((item, index) => {
          return (
            <li
              key={index}
              className="w-full cursor-pointer rounded font-semibold text-[#30291D] hover:bg-[#DCCCE1]/30"
            >
              {isLink ? (
                <Link
                  className={'w-full p-2'}
                  href={item.path}
                  target={item.isExternalLink ? '_blank' : ''}
                >
                  {item.name}
                </Link>
              ) : (
                <div className={'w-full p-2'} onClick={() => onClick(item)}>
                  {item.name}
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Dropdown
