import Image from 'next/image'
import { SUPPORTED_WALLETS, WalletId } from '../../constants/web3/wallets'
import { useWalletSelected } from '../../context/WalletSelectedContext'
import { useWeb3 } from '../../context/Web3Context'
import Modal from '../Modal'
import { useEffect, useState } from 'react'
import { getIsMetaMaskWallet } from '../../constants/web3/connection'
import { SupportedChainId } from '../../constants/web3/supportedChainId'

interface ISelectWalletModal {
  isHideMobileNav: boolean
}

export default function SelectWalletModal({ isHideMobileNav }: ISelectWalletModal) {
  const { connect, chainId } = useWeb3()
  const { isOpen, close: closeModal } = useWalletSelected()
  const [isMetamask, setIsMetamask] = useState(false)

  useEffect(() => {
    setIsMetamask(getIsMetaMaskWallet())
  }, [])

  const filteredWallets = Object.values(SUPPORTED_WALLETS).map((wallet) => {
    if (
      (isMetamask && wallet.id === WalletId.INJECTED) ||
      (!isMetamask && wallet.id === WalletId.METAMASK)
    ) {
      return {
        ...wallet,
        isHidden: true,
      }
    }

    // Hide core wallet if the current chain is not AVAX or Fuji
    if (
      wallet.id === WalletId.COREWALLET &&
      chainId !== SupportedChainId.AVAX &&
      chainId !== SupportedChainId.FUJI
    ) {
      return {
        ...wallet,
        isHidden: true,
      }
    }

    return wallet
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      width={400}
      title="Connect a Wallet"
      isHideMobileNav={isHideMobileNav}
    >
      <div className="flex flex-row justify-between font-Work">
        <ul className="block w-full">
          {filteredWallets
            .filter((wallet) => !wallet.isHidden)
            .map((wallet) => (
              <li key={`SelectWalletModal_${wallet.id}`}>
                <button
                  id={`select-wallet-${wallet.name}`}
                  className="relative mt-4 flex h-12 w-full items-center justify-center rounded-lg border-2 border-wombatBrown2 hover:bg-wombatYellow2"
                  onClick={() => {
                    connect(wallet)
                    closeModal()
                  }}
                >
                  {wallet.id === WalletId.INJECTED && !isMetamask ? (
                    <div className="flex items-center gap-2">
                      <Image
                        src={wallet.icon}
                        alt={wallet.name}
                        className="rounded-full"
                        height={32}
                        width={32}
                      />
                      <span>{wallet.name}</span>
                    </div>
                  ) : (
                    <Image
                      src={wallet.groupIcon.src}
                      width={wallet.groupIcon.width || 138}
                      height={wallet.groupIcon.height || 25}
                    />
                  )}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </Modal>
  )
}
