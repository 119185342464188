import { useAppSelector } from '../hooks'
import { selectWpPoints, selectWpPointsForUser, selectWpPointsLoading } from './selectors'

export const useWpPoints = () => {
  return useAppSelector(selectWpPoints)
}

export const useWpPointsForUser = (user: string) => {
  return useAppSelector((state) => selectWpPointsForUser(state, user))
}

export const useWppNeedAuth = () => {
  return useAppSelector((state) => state.wpp.needAuth)
}

export const useWpPointsLoading = () => {
  return useAppSelector(selectWpPointsLoading)
}
