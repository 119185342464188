import { useClient } from 'wagmi'
import Button from '../Button'
import Modal from '../Modal'

interface Props {
  isOpen: boolean
  onClose: () => void
  isHideMobileNav?: boolean
}

const CustomRpcs = [
  {
    name: 'Ethereum Private Network',
    chain: {
      chainId: '0x1',
      chainName: 'ETH MEV Protect Wombat x bloxRoute',
      rpcUrls: ['https://wombat-eth.rpc.blxrbdn.com/'],
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
    },
  },
  {
    name: 'BNB Private Network',
    chain: {
      chainId: '0x38',
      chainName: 'BNB MEV Protect Wombat x bloxRoute',
      rpcUrls: ['https://wombat-bnb.rpc.blxrbdn.com/'],
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
    },
  },
  // {
  //   name: 'Polygon Private Network',
  //   chain: {
  //     chainId: '0x89',
  //     chainName: 'Polygon MEV Protect Wombat x bloxRoute',
  //     rpcUrls: ['https://wombat-polygon.rpc.blxrbdn.com/'],
  //     nativeCurrency: {
  //       name: 'MATIC',
  //       symbol: 'MATIC',
  //       decimals: 18,
  //     },
  //   },
  // },
]

const CustomRpcModal = ({ isOpen, onClose, isHideMobileNav }: Props) => {
  const client = useClient()
  const handleAddNetwork = async (chain: unknown) => {
    const provider = await client.connector?.getProvider()
    provider?.request({
      method: 'wallet_addEthereumChain',
      params: [chain],
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={550}
      height={440}
      title={<div className="text-lg">Wombat Private RPC</div>}
      variant="bordered"
      isHideMobileNav={isHideMobileNav}
    >
      <div className="flex flex-col items-stretch gap-4">
        <div className={'border border-wombatYellow bg-wombatYellow4 p-4'}>
          Enhance the security and confidentiality of your transactions with Wombat Private RPC,
          supported by bloXroute. By leveraging this service, you can effectively safeguard your
          transactions from Miner Extractable Value (MEV) and front-running attacks, ensuring a more
          secure and reliable trading experience.
        </div>
        {CustomRpcs.map((item) => (
          <div className={'flex items-center justify-between'} key={item.name}>
            <span>{item.name}</span>
            <Button
              onClick={() => {
                handleAddNetwork(item.chain)
              }}
              className="w-auto grow-0 rounded-lg !text-sm"
            >
              Add Network
            </Button>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default CustomRpcModal
