import { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  isOpen: boolean
  setIsOpen: (p: false) => void
}

function Drawer({ children, isOpen, setIsOpen }: Props) {
  return (
    <main
      className={
        ' fixed inset-0 z-20 transform overflow-hidden bg-gray-900 bg-opacity-25 ease-in-out ' +
        (isOpen
          ? 'translate-x-0 opacity-100 transition-opacity duration-500  '
          : 'translate-x-full opacity-0 transition-all delay-500')
      }
      onClick={() => setIsOpen(false)}
    >
      <section
        className={
          'delay-400 absolute  right-0 w-8/12 transform bg-wombatCream py-5 shadow-xl transition-all duration-500 ease-in-out md:h-full md:w-3/12  ' +
          (isOpen ? ' translate-x-0' : 'translate-x-full')
        }
      >
        <article className="relative flex h-full w-full flex-col items-center space-y-6 overflow-y-auto pt-6">
          {children}
        </article>
      </section>
      <section
        onClick={() => {
          setIsOpen(false)
        }}
      ></section>
    </main>
  )
}

export default Drawer
