import { RootState } from '../store'
import { createSelector } from 'reselect'

export const selectWpPoints = (state: RootState) => state.wpp.points

export const selectWpPointsLoading = (state: RootState) => state.wpp.pointsLoading

export const selectWpPointsForUser = createSelector(
  [selectWpPoints, (_: RootState, user: string) => user],
  (points, user) => points[user.toLowerCase()] || 0
)
