const IconMenu = ({ bgColor = '#B672D6', color = 'white' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28 0V28H0V0H28Z" fill={bgColor} />
      <rect x="8" y="8" width="13" height="1.73077" rx="0.865385" fill={color} />
      <rect x="8" y="13.1346" width="13" height="1.73077" rx="0.865385" fill={color} />
      <rect x="11.25" y="18.2692" width="9.75" height="1.73077" rx="0.865385" fill={color} />
    </svg>
  )
}

export default IconMenu
