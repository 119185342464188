export type IconProps = {
  fillColor?: string
  strokeColor?: string
  height?: number
  width?: number
  className?: string
  size?: number
}

const IconChevDown = ({ width = 14, height = 14, strokeColor }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 8L12 15L5 8"
        stroke={strokeColor || 'inherit'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconChevDown
