import Button from '../Button'

type Props = {
  connectWalletButtonClicked: () => void
}

export function ConnectWalletButton({ connectWalletButtonClicked }: Props) {
  return (
    <Button
      id="connect-wallet-button"
      className="button-hover-opacity-light bg-linearGradientApprove py-1.5 md:py-0"
      onClick={() => connectWalletButtonClicked()}
    >
      <p className="whitespace-nowrap font-Jamjuree text-base">
        CONNECT<span className="hidden md:inline"> WALLET</span>
      </p>
    </Button>
  )
}
